<template>

  <div>

    <branch-list-add-new
      :is-add-new-branch-sidebar-active.sync="isAddNewBranchSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />
    <branch-list-edit
      :is-edit-branch-sidebar-active.sync="isEditBranchSidebarActive"
      :branch-id.sync="BranchID"
      :branch-name.sync="BranchName"
      :branch-code.sync="BranchCode"
      :branch-city.sync="BranchCity"
      :branch-address.sync="BranchAddress"
      :branch-phone.sync="BranchPhone"
      :branch-fax.sync="BranchFax"
      :branch-region-id.sync="BranchRegionID"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewBranchSidebarActive = true"
              >
                <span class="text-nowrap">Add Branch</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refBranchListTable"
        class="position-relative"
        :items="fetchBranchs"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Branch Name -->
        <template #cell(branch_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.branch_name }}</span>
          </div>
        </template>

        <!-- Column: Branch Code -->
        <template #cell(branch_code)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.branch_code }}</span>
          </div>
        </template>

        <!-- Column: Branch City -->
        <template #cell(branch_city)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.branch_city }}</span>
          </div>
        </template>

        <!-- Column: Branch region -->
        <template #cell(branch_region)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.region ? data.item.region.region_name : '-'  }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editBranch(  data.item ); isEditBranchSidebarActive = true;">
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteBranch( data.item.branch_id )">
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalBranchs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import axios from 'axios'
import vSelect from 'vue-select'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
// import BranchesListFilters from './BranchesListFilters.vue'
import BranchList from './BranchList'
import branchModule from '../branchModule'
import BranchListAddNew from './BranchAddNew.vue'
import BranchListEdit from './BranchEdit.vue'

const userRole = ''
const BranchData = null
export default {
  components: {
    // BranchesListFilters,
    BranchListEdit,
    BranchListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  mounted() {
    this.userRole = this.$cookies.get('UserRole')
    this.$root.$on('refreshTable', () => {
    this.$refs.refBranchListTable.refresh()
  })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = 'app-unit'

    // Register module
    if (!store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.registerModule(BRANCH_APP_STORE_MODULE_NAME, branchModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
    })

    const isAddNewBranchSidebarActive = ref(false)
    const isEditBranchSidebarActive = ref(false)
    const BranchID = ref('')
    const BranchName = ref('')
    const BranchCode = ref('')
    const BranchCity = ref('')
    const BranchAddress = ref('')
    const BranchPhone = ref('')
    const BranchFax = ref('')
    const BranchRegionID = ref('')
    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchBranchs,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalBranchs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBranchListTable,
      refetchData,

      // UI
      resolveBranchRoleVariant,
      resolveBranchRoleIcon,
      resolveBranchStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = BranchList()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]
    return {

      // Sidebar
      BranchID,
      BranchName,
      BranchCode,
      BranchCity,
      BranchAddress,
      BranchPhone,
      BranchFax,
      BranchRegionID,
      isEditBranchSidebarActive,
      isAddNewBranchSidebarActive,
      userRole,
      BranchData,
      fetchBranchs,
      fetchRoles,
      tableColumns,
      perPage,
      currentPage,
      totalBranchs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBranchListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveBranchRoleVariant,
      resolveBranchRoleIcon,
      resolveBranchStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
  methods: {
      deleteBranch(id) {
        const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_BRANCH}/${id}`
          axios
              .get(url, { headers })
              .then(response => {
                console.log(response)
                if (response.data.success === true) {
                  this.$refs.refBranchListTable.refresh()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Congratulation',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Your data has been deleted!',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
                }
              })
              .catch(err => {
                  console.log(err)
              })
      },
      editBranch(data) {
        const parsed = data
        this.BranchID = parsed.branch_id.toString()
        this.BranchName = parsed.branch_name
        this.BranchCode = parsed.branch_code
        this.BranchCity = parsed.branch_city
        this.BranchAddress = parsed.branch_address
        this.BranchPhone = parsed.branch_phone
        this.BranchFax = parsed.branch_fax
        this.BranchRegionID = parsed.branch_region_id
      },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
