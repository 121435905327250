<template>
  <b-sidebar
    id="add-new-branchsidebar"
    :visible="isAddNewBranchSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-branch-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Branch
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
            ref="refFormObserver"
            #default="{invalid}"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="addNewBranch"
          @reset.prevent="resetForm"
        >
          <!-- Kota -->
          <validation-provider
            #default="validationContext"
            name="Branch Name"
            rules="required"
          >
            <b-form-group
              label="Branch Name"
              label-for="Branch Name"
            >
              <b-form-input
                id="BranchName"
                v-model="BranchName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Branch Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
           <!-- Branch Code -->
          <validation-provider
            #default="validationContext"
            name="Branch Code"
            rules="required"
          >
            <b-form-group
              label="Branch Code"
              label-for="BranchCode"
            >
              <b-form-input
                id="BranchCode"
                v-model="BranchCode"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Branch Code"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
           <!-- Branch City -->
          <validation-provider
            #default="validationContext"
            name="Branch City"
            rules="required"
          >
            <b-form-group
              label="Branch City"
              label-for="BranchCity"
            >
              <b-form-input
                id="BranchCity"
                v-model="BranchCity"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Branch City"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
           <!-- Branch Address -->
          <validation-provider
            #default="validationContext"
            name="Branch Address"
            rules="required"
          >
            <b-form-group
              label="Branch Address"
              label-for="BranchAddress"
            >
              <b-form-input
                id="BranchAddress"
                v-model="BranchAddress"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Branch Address"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
           <!-- Branch Phone -->
          <validation-provider
            #default="validationContext"
            name="Branch Phone"
            rules="required"
          >
            <b-form-group
              label="Branch Phone"
              label-for="BranchPhone"
            >
              <b-form-input
                id="BranchPhone"
                v-model="BranchPhone"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Branch Phone"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
           <!-- Branch Fax -->
          <validation-provider
            #default="validationContext"
            name="Branch Fax"
          >
            <b-form-group
              label="Branch Fax"
              label-for="BranchFax"
            >
              <b-form-input
                id="BranchFax"
                v-model="BranchFax"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Branch Fax"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Branch Region -->
          <validation-provider
            #default="validationContext"
            name="Branch Region"
          >
            <b-form-group
              label="Branch Region"
              label-for="RegionID"
              :state="getValidationState(validationContext)"
            >
              <v-select-new
                v-model="RegionID"
                name="RegionID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="regions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="RegionID"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
                block
                :disabled="invalid && submitButtonDisabled"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { ref } from '@vue/composition-api'

import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import useBranchsList from './useBranchsList'

const BranchName = ''
const BranchCode = ''
const BranchCity = ''
const BranchAddress = ''
const BranchPhone = ''
const BranchFax = ''
const regions = []
const RegionID = ''
const submitButtonDisabled = false
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewBranchSidebarActive',
    event: 'update:is-add-new-branch-sidebar-active',
  },
  props: {
    isAddNewBranchSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      submitButtonDisabled,
      regions,
      RegionID,
      required,
      alphaNum,
      BranchName,
      BranchCode,
      BranchCity,
      BranchAddress,
      BranchPhone,
      BranchFax,
    }
  },
  setup() {
    const blankBranchData = {
      BranchName: '',
      BranchCode: '',
    }
    const unitData = ref(blankBranchData)
    const resetunitData = () => {
      unitData.value = blankBranchData
    }

    const onSubmit = () => {
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetunitData)

    return {
      BranchName,
      BranchCode,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  mounted() {
    this.getRegions()
  },
  methods: {
    getRegions() {
          const userToken = this.$cookies.get('userToken')
          const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          }
          axios
              .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_REGION}`, { headers })
              .then(response => {
                const regionsJoin = []
                response.data.data.map(elem => {
                  regionsJoin.push({ label: elem.region_name, value: elem.region_id, slug: elem.region_slug })
                })
                this.regions = regionsJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
    addNewBranch() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
        const payload = {
          branch_name: this.BranchName,
          branch_code: this.BranchCode,
          branch_city: this.BranchCity,
          branch_address: this.BranchAddress,
          branch_phone: this.BranchPhone,
          branch_fax: this.BranchFax,
          branch_region_id: this.RegionID,
          time: moment(),
          }
          axios
          .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_ADD_BRANCH}`, payload, { headers })
          .then(response => {
            this.submitButtonDisabled = false
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          if (response.data.success === true) {
                this.$root.$emit('refreshTable', 'refreshTable')
                this.isAddNewBranchSidebarActive = false
                this.BranchName = ""
                this.BranchCode = ""
                // this.$parent.refBranchListTable.refresh()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Your data has been inserted!',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              }
              }).catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: 'Something went wrong',
                  },
                })
              })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-branch-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
